import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "RubyKaigi 2024 に Ruby Sponsor として協賛しました！",
  "date": "2024-05-24T09:29:14.000Z",
  "slug": "entry/2024/05/24/182914",
  "tags": [],
  "hero": "./IMG_5123.png",
  "heroAlt": "RubyKaigi 2024 に Ruby Sponsor として協賛しました！"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。人材プラットフォーム本部プロダクト開発室 第五開発グループ所属の寺内です。
新卒からメドレーに入社して今年で 4 年目のエンジニアで、老人ホーム・介護施設の検索サイトである `}<a parentName="p" {...{
        "href": "https://kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`の開発を担当しています。`}</p>
    <p>{`メドレーは 5 月 15 日から 17 日に 沖縄県那覇市の`}<a parentName="p" {...{
        "href": "https://www.nahart.jp/"
      }}>{`那覇文化芸術劇場なはーと`}</a>{` にて開催された `}<a parentName="p" {...{
        "href": "https://rubykaigi.org/2024/"
      }}>{`RubyKaigi 2024`}</a>{`に Ruby Sponsor として協賛しました！
RubyKaigi 2024 は Ruby をテーマとした国際的なカンファレンスで、世界中から様々な Ruby エンジニアが集う大規模なイベントです。`}</p>
    <p>{`エンジニアとエンジニア採用担当の計 12 名が現地参加し、たくさんの方々と交流させて頂きました。また、スポンサー LT もさせていただきました。
今回は会場・ブース・発表の様子と、スポンサー LT の内容をご紹介します。`}</p>
    <h1>{`会場の様子`}</h1>
    <p>{`会場となった那覇文化芸術劇場なはーとは、国際通りからほど近い場所でありながら閑静な市街地にありました。
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c8164cb0fffcd6ecf1387fa7ff8adf05/e1596/IMG_5169.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAv/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAXMkWgQYA//EABgQAQADAQAAAAAAAAAAAAAAAAEAAhID/9oACAEBAAEFAjNpamjJKVnLm4BT/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAECERL/2gAIAQMBAT8Bc6jiT//EABkRAAIDAQAAAAAAAAAAAAAAAAABAgMREv/aAAgBAgEBPwFTxndh/8QAGhAAAgIDAAAAAAAAAAAAAAAAAAEQMhEhQf/aAAgBAQAGPwLTM9mzLM//xAAaEAEAAgMBAAAAAAAAAAAAAAABABEhMUFR/9oACAEBAAE/IVq2MS0wvsow3L1LjGrDyXiif//aAAwDAQACAAMAAAAQL9//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFh/9oACAEDAQE/EFSZkf/EABkRAQACAwAAAAAAAAAAAAAAAAEAMSGR8P/aAAgBAgEBPxBsdxdvtT//xAAdEAEAAwABBQAAAAAAAAAAAAABABExIUFRYXGB/9oACAEBAAE/EAKs+aHkgzLkMd4awnyEJRQbrPUuJly6CZdwUvAJ/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 5169",
            "title": "IMG 5169",
            "src": "/static/c8164cb0fffcd6ecf1387fa7ff8adf05/e5166/IMG_5169.jpg",
            "srcSet": ["/static/c8164cb0fffcd6ecf1387fa7ff8adf05/f93b5/IMG_5169.jpg 300w", "/static/c8164cb0fffcd6ecf1387fa7ff8adf05/b4294/IMG_5169.jpg 600w", "/static/c8164cb0fffcd6ecf1387fa7ff8adf05/e5166/IMG_5169.jpg 1200w", "/static/c8164cb0fffcd6ecf1387fa7ff8adf05/d9c39/IMG_5169.jpg 1800w", "/static/c8164cb0fffcd6ecf1387fa7ff8adf05/e1596/IMG_5169.jpg 2048w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`沖縄らしさを感じさせる建築デザイン`}</em></p>
    <p>{`建物内は 4 階まで吹き抜けとなっていて開放感が溢れている会場でしたが、セッション間はこのように各社のブースを訪問する人々でいっぱいになり、RubyKaigi の盛況ぶりを感じることができました。
なんと今年の来場者数は約 1400 人だったそうです！
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c8c5780d531dc52ca36bc18140a1954e/f01b8/IMG_5151_mosaic.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUCAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAAB1QVVC6FpH//EABsQAAIDAAMAAAAAAAAAAAAAAAECAAQRAxQi/9oACAEBAAEFAu1kNsRbGwp40leI4v8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGxAAAQQDAAAAAAAAAAAAAAAAAAERMUEQIWH/2gAIAQEABj8ChyFLHeza8x//xAAcEAADAAIDAQAAAAAAAAAAAAAAARExUSFBkaH/2gAIAQEAAT8h1ZoWNfAlb22McMfR2grwKSiyf//aAAwDAQACAAMAAAAQIz//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPxCH/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAECAQE/ELqP/8QAHRABAAIDAAMBAAAAAAAAAAAAAREhADFBcYGxwf/aAAgBAQABPxBQFy6JO2+sUAY6Q+5Fgik+JiFBNIk2v8y8UELHKWG94wbhul6M/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 5151 mosaic",
            "title": "IMG 5151 mosaic",
            "src": "/static/c8c5780d531dc52ca36bc18140a1954e/e5166/IMG_5151_mosaic.jpg",
            "srcSet": ["/static/c8c5780d531dc52ca36bc18140a1954e/f93b5/IMG_5151_mosaic.jpg 300w", "/static/c8c5780d531dc52ca36bc18140a1954e/b4294/IMG_5151_mosaic.jpg 600w", "/static/c8c5780d531dc52ca36bc18140a1954e/e5166/IMG_5151_mosaic.jpg 1200w", "/static/c8c5780d531dc52ca36bc18140a1954e/f01b8/IMG_5151_mosaic.jpg 1580w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`RubyKaigi 2024 の公式配布グッズは、例年とは異なり T シャツではなくかりゆしとビーチサンダルでした！運営の粋な計らいのおかげで、会期中会場がカラフルでしたね。
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c981d9ad49c170805fae35247561bede/d2602/IMG_8876.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAABaPRGdnME/8QAGhAAAwADAQAAAAAAAAAAAAAAAQIDAAQREv/aAAgBAQABBQKlG6LUOJsnnoUFpFJxYFP/xAAZEQACAwEAAAAAAAAAAAAAAAAAEgECMVH/2gAIAQMBAT8BauErw//EABYRAAMAAAAAAAAAAAAAAAAAABARUf/aAAgBAgEBPwFQf//EAB0QAAIBBAMAAAAAAAAAAAAAAAABAhESITEiMkH/2gAIAQEABj8C9iN1wjMalkxW9Vs5bP/EABsQAAIDAQEBAAAAAAAAAAAAAAERACExQVGB/9oACAEBAAE/IQXKOCHC1/NhG89FRWz5Gsdhdjtdtn//2gAMAwEAAgADAAAAEBz/AP/EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QExAXSf/EABcRAAMBAAAAAAAAAAAAAAAAAAARMWH/2gAIAQIBAT8Qah6f/8QAGxABAQEAAgMAAAAAAAAAAAAAAREAITFBcZH/2gAIAQEAAT8QbT0UMGQgiUT0e80ma5Y+bpwWiojnIGrWV4ZnbNpwtIb/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 8876",
            "title": "IMG 8876",
            "src": "/static/c981d9ad49c170805fae35247561bede/e5166/IMG_8876.jpg",
            "srcSet": ["/static/c981d9ad49c170805fae35247561bede/f93b5/IMG_8876.jpg 300w", "/static/c981d9ad49c170805fae35247561bede/b4294/IMG_8876.jpg 600w", "/static/c981d9ad49c170805fae35247561bede/e5166/IMG_8876.jpg 1200w", "/static/c981d9ad49c170805fae35247561bede/d9c39/IMG_8876.jpg 1800w", "/static/c981d9ad49c170805fae35247561bede/df51d/IMG_8876.jpg 2400w", "/static/c981d9ad49c170805fae35247561bede/d2602/IMG_8876.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`2 日目から始まったスタンプラリーでは来場者のブース訪問がより活発になったのを感じました。ブースを巡ってスタンプを集めると限定バッジがもらえました。
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/18204e9200f445250c126af00de880a6/e1596/IMG_5342.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBP/EABYBAQEBAAAAAAAAAAAAAAAAAAIBA//aAAwDAQACEAMQAAABs1Im5xB5f//EABkQAQADAQEAAAAAAAAAAAAAAAEAAhESA//aAAgBAQABBQKl3lRi5Nyj59mZP//EABcRAQADAAAAAAAAAAAAAAAAAAABERL/2gAIAQMBAT8BpOn/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGn/8QAGxAAAgEFAAAAAAAAAAAAAAAAAAExAhAREpH/2gAIAQEABj8CdajtpGZ2aJP/xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAxUbH/2gAIAQEAAT8heF1G4QvNVA/MqTy6jQy463//2gAMAwEAAgADAAAAEN8f/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPxDCNXIf/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARYf/aAAgBAgEBPxDiEy//xAAdEAEBAAICAwEAAAAAAAAAAAABEQAhMUFhgaHh/9oACAEBAAE/EKOTeOB0dzfOGYkZF0U/cR2Q1oY8YauF+9DJZRBTdJ2ZNVprXBZPmf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 5342",
            "title": "IMG 5342",
            "src": "/static/18204e9200f445250c126af00de880a6/e5166/IMG_5342.jpg",
            "srcSet": ["/static/18204e9200f445250c126af00de880a6/f93b5/IMG_5342.jpg 300w", "/static/18204e9200f445250c126af00de880a6/b4294/IMG_5342.jpg 600w", "/static/18204e9200f445250c126af00de880a6/e5166/IMG_5342.jpg 1200w", "/static/18204e9200f445250c126af00de880a6/d9c39/IMG_5342.jpg 1800w", "/static/18204e9200f445250c126af00de880a6/e1596/IMG_5342.jpg 2048w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`バッジ交換時にスタッフさんが全力で拍手をしてくださるのでとても幸せな気持ちになりました！`}</em></p>
    <h1>{`弊社ブースの様子`}</h1>
    <p>{`メドレーブースではうちわやビーチサンダルなどの沖縄らしいものをはじめ、医療事業を手がける企業のアピールとして絆創膏をノベルティとしてご用意しました。
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f03382cc715e1bf813561d3ffd821b77/e1596/IMG_5127.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "77%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAECAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAbVqRSIBt//EABoQAAMAAwEAAAAAAAAAAAAAAAECAwASIhP/2gAIAQEAAQUCrL0xI6A8lW4OPcI3/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARMf/aAAgBAwEBPwGBzl//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAADAAIDAAAAAAAAAAAAAAAAAQIQEhExUf/aAAgBAQAGPwLUlKjwnnt41ps//8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIaExYf/aAAgBAQABPyFzZpnolcug9VGU1pcVIYnZrgPk/9oADAMBAAIAAwAAABDsz//EABURAQEAAAAAAAAAAAAAAAAAAAEA/9oACAEDAQE/EBmBn//EABgRAAIDAAAAAAAAAAAAAAAAAAABESEx/9oACAECAQE/EIG70//EAB0QAQEAAgMAAwAAAAAAAAAAAAERACExQVFhcbH/2gAIAQEAAT8QNdmCluOyB3oHH6dYk6lWXfzob95LsNg7S5DjhLsVfcbIkNC6d+5//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 5127",
            "title": "IMG 5127",
            "src": "/static/f03382cc715e1bf813561d3ffd821b77/e5166/IMG_5127.jpg",
            "srcSet": ["/static/f03382cc715e1bf813561d3ffd821b77/f93b5/IMG_5127.jpg 300w", "/static/f03382cc715e1bf813561d3ffd821b77/b4294/IMG_5127.jpg 600w", "/static/f03382cc715e1bf813561d3ffd821b77/e5166/IMG_5127.jpg 1200w", "/static/f03382cc715e1bf813561d3ffd821b77/d9c39/IMG_5127.jpg 1800w", "/static/f03382cc715e1bf813561d3ffd821b77/e1596/IMG_5127.jpg 2048w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`靴擦れしてしまった方に絆創膏をお渡ししたらとても喜んでいただきました`}</em></p>
    <p>{`アンケートパネルを用いて参加者の方が抱えている医療体験のお悩みを伺いました。
伺ったお悩みからわかる課題に対してメドレーがプロダクトを通じてどのように向き合い・解決しているのかをご紹介しました。
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/be6f60bbf86047d94989d57ef95c9400/f01b8/IMG_5381_mosaic.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAUDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAHs2lhWqklL/8QAGxABAAICAwAAAAAAAAAAAAAAAgEDABESISP/2gAIAQEAAQUClLS6go6qSVk2em+ef//EABgRAAIDAAAAAAAAAAAAAAAAAAECAxAR/9oACAEDAQE/AZFw1//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/ARn/xAAaEAADAAMBAAAAAAAAAAAAAAAAAREhIjFR/9oACAEBAAY/AtZRcpyGWTwbZ//EABoQAQEBAQEBAQAAAAAAAAAAAAERAEEhMXH/2gAIAQEAAT8hQinNz9QfF7+zKK0eYSKlSZrRbZ7hP6rzf//aAAwDAQACAAMAAAAQlz//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQMBAT8QAiy//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QZJ//xAAbEAEBAQEBAAMAAAAAAAAAAAABEQAhMUFhof/aAAgBAQABPxB5YGZBftyqr1JDzjrSuCq+MgJsXjxwJ/RVS/meuKJQeG//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 5381 mosaic",
            "title": "IMG 5381 mosaic",
            "src": "/static/be6f60bbf86047d94989d57ef95c9400/e5166/IMG_5381_mosaic.jpg",
            "srcSet": ["/static/be6f60bbf86047d94989d57ef95c9400/f93b5/IMG_5381_mosaic.jpg 300w", "/static/be6f60bbf86047d94989d57ef95c9400/b4294/IMG_5381_mosaic.jpg 600w", "/static/be6f60bbf86047d94989d57ef95c9400/e5166/IMG_5381_mosaic.jpg 1200w", "/static/be6f60bbf86047d94989d57ef95c9400/f01b8/IMG_5381_mosaic.jpg 1580w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`特に回答が多かったのが `}<strong parentName="p">{`「待ち時間が」長い`}</strong>{` という課題でした。
メドレーの提供するオンライン診療・服薬指導アプリ「`}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/"
      }}>{`CLINICS`}</a>{`」では病院や調剤薬局の予約、事前の問診票入力などが可能です。オンラインによる診療・服薬指導（薬剤師によるお薬の説明）を予約した場合は待ち時間だけではなく移動時間も削減されることや、直近では Uber Eats との連携で服薬指導後30分程度で自宅までお薬を届けてもらうことも可能になったことをご説明すると、「ここまで便利になっていることは知らなかった！」と驚きのお声を多数いただきました。
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1170px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "104.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAVABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAMCAQT/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgP/2gAMAwEAAhADEAAAAfdZu85rI0pwNA//xAAaEAACAgMAAAAAAAAAAAAAAAAAEQEQAiEx/9oACAEBAAEFAhV0jWLEJ1//xAAZEQACAwEAAAAAAAAAAAAAAAABAgAQIVH/2gAIAQMBAT8BVANmcv8A/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAECEBH/2gAIAQIBAT8Bcjb/AP/EABkQAAMBAQEAAAAAAAAAAAAAAAARIRAxcf/aAAgBAQAGPwIhx+Cotuf/xAAbEAACAwEBAQAAAAAAAAAAAAAAAREhMVFBcf/aAAgBAQABPyFKL8EsKVvCG706oqNHxC3N6aciVCTCRKj/2gAMAwEAAgADAAAAEMcQAP/EABkRAAIDAQAAAAAAAAAAAAAAAAABECFhcf/aAAgBAwEBPxBIms4GR//EABgRAAMBAQAAAAAAAAAAAAAAAAABERAh/9oACAECAQE/EHviKyH/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUFRYXH/2gAIAQEAAT8QWyMdxrBbTSxTmBj2LAHwuUCBrvC+b5HN+2MovFhaiCOWdfI9QYFGw5o1n5BSpbU//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMG 4018 2",
          "title": "IMG 4018 2",
          "src": "/static/4a1dd6bff9703a4d448858b3e6a7b49a/ad68d/IMG_4018_2.jpg",
          "srcSet": ["/static/4a1dd6bff9703a4d448858b3e6a7b49a/f93b5/IMG_4018_2.jpg 300w", "/static/4a1dd6bff9703a4d448858b3e6a7b49a/b4294/IMG_4018_2.jpg 600w", "/static/4a1dd6bff9703a4d448858b3e6a7b49a/ad68d/IMG_4018_2.jpg 1170w"],
          "sizes": "(max-width: 1170px) 100vw, 1170px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`会場の那覇付近でもこれだけの医療機関が見つかりました`}</em></p>
    <p>{`また、直接的ではありませんが、待ち時間の長さには人員不足や院内オペレーション、利用システムも影響していることがあります。それらをジョブメドレーや医療機関向けのシステム（`}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/"
      }}>{`CLINICS`}</a>{`、`}<a parentName="p" {...{
        "href": "https://pcmed.jp/product-mall/"
      }}>{`MALL`}</a>{`、`}<a parentName="p" {...{
        "href": "https://pharms-cloud.com/"
      }}>{`Pharms`}</a>{`、`}<a parentName="p" {...{
        "href": "https://dentis-cloud.com/"
      }}>{`Dentis`}</a>{`）を通じてサポートしていること、一つの課題に対して様々なアプローチをとっていることなどをお話ししました。
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABRrsrGQnC/8QAGxAAAwACAwAAAAAAAAAAAAAAAQIDAAQREyL/2gAIAQEAAQUCeBUgVKJPyOcmnWmzYzp//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8BR//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EAB8QAAIBAwUBAAAAAAAAAAAAAAABEQISIQMiIzFBYf/aAAgBAQAGPwLbRiPSdM5E7jLlDoT+lsvo/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARIUFx/9oACAEBAAE/Ibr1rsReChiEE9BA8LpZBZqXUorGP//aAAwDAQACAAMAAAAQe9//xAAXEQEAAwAAAAAAAAAAAAAAAAAAESFR/9oACAEDAQE/EKxB/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAhUf/aAAgBAgEBPxDZXt//xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUFRYXH/2gAIAQEAAT8Qcg5j6HO9zfWjVaO17+wBcStlK84iSjiDVl8uBUEvqCUqFc27+T//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IMG 5318",
          "title": "IMG 5318",
          "src": "/static/df3e2060c4b2c9b0b1c03b46700105aa/e5166/IMG_5318.jpg",
          "srcSet": ["/static/df3e2060c4b2c9b0b1c03b46700105aa/f93b5/IMG_5318.jpg 300w", "/static/df3e2060c4b2c9b0b1c03b46700105aa/b4294/IMG_5318.jpg 600w", "/static/df3e2060c4b2c9b0b1c03b46700105aa/e5166/IMG_5318.jpg 1200w", "/static/df3e2060c4b2c9b0b1c03b46700105aa/d9c39/IMG_5318.jpg 1800w", "/static/df3e2060c4b2c9b0b1c03b46700105aa/e1596/IMG_5318.jpg 2048w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`ツアー風 T シャツには今年メドレーがスポンサードしたイベントの一覧が掲載されています`}</em></p>
    <p>{`メドレーブースにお越しいただいた皆様、ありがとうございました！
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/fb5bd921976030e8a08cb525a48de2a1/d2602/IMG_3804.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAv/EABYBAQEBAAAAAAAAAAAAAAAAAAMBAv/aAAwDAQACEAMQAAABdyunNXiWIf8A/8QAGxAAAgMAAwAAAAAAAAAAAAAAAQIDBBEAEjH/2gAIAQEAAQUCPFfqw83aUBLV4iSn/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8Bqv/EABYRAQEBAAAAAAAAAAAAAAAAAAABAv/aAAgBAgEBPwFMv//EABwQAAIBBQEAAAAAAAAAAAAAAAABQQIQESExkf/aAAgBAQAGPwLng5aNiK8wrf/EABoQAQADAQEBAAAAAAAAAAAAAAEAESFRMXH/2gAIAQEAAT8htZZNzk6wbx03MnA/YpT6irLRXZU2Dez/2gAMAwEAAgADAAAAELPP/8QAGBEAAgMAAAAAAAAAAAAAAAAAABEBITH/2gAIAQMBAT8QVIm+H//EABcRAQEBAQAAAAAAAAAAAAAAAAEAETH/2gAIAQIBAT8QBtDt/8QAHBABAAIDAAMAAAAAAAAAAAAAARExACFBUZGx/9oACAEBAAE/EClJZpW6OHfWBcBoENfdmFHpq375xh5eBuSIwjuJGyu3kodCCWgoz//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 3804",
            "title": "IMG 3804",
            "src": "/static/fb5bd921976030e8a08cb525a48de2a1/e5166/IMG_3804.jpg",
            "srcSet": ["/static/fb5bd921976030e8a08cb525a48de2a1/f93b5/IMG_3804.jpg 300w", "/static/fb5bd921976030e8a08cb525a48de2a1/b4294/IMG_3804.jpg 600w", "/static/fb5bd921976030e8a08cb525a48de2a1/e5166/IMG_3804.jpg 1200w", "/static/fb5bd921976030e8a08cb525a48de2a1/d9c39/IMG_3804.jpg 1800w", "/static/fb5bd921976030e8a08cb525a48de2a1/df51d/IMG_3804.jpg 2400w", "/static/fb5bd921976030e8a08cb525a48de2a1/d2602/IMG_3804.jpg 4032w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`参加したメドレーメンバー全員で`}</em></p>
    <h1>{`発表の様子`}</h1>
    <p>{`3 日間にわたってさまざまなセッションがありましたが、私が気になった以下のセッションについて少しご紹介します。`}</p>
    <ul>
      <li parentName="ul">{`Keynote`}</li>
      <li parentName="ul">{`DAY1 rubykaigiB 「Long journey of Ruby standard library」`}</li>
      <li parentName="ul">{`DAY2 rubykaigiB 「Let's use LLMs from Ruby 〜 Refine RBS types using LLM 〜」`}</li>
      <li parentName="ul">{`Matz Keynote`}</li>
    </ul>
    <h2>{`Keynote`}</h2>
    <p>{`今年の Keynote は tomoya ishida さんによる「Writing Weird Code」という発表でした。
Quine というコードと実行結果が同じ内容になるプログラムに関する発表で、Rubyの表現力と楽しさを紹介するものでした。
中でも発表中に行われた一人 TRICK(Transcendental Ruby Imbroglio Contest for RubyKaigi)では、Most 〇〇 と名付けられたグラフィカルなコードが紹介され、会場は大盛りあがりでした！
紹介されていたものは GitHub にて`}<a parentName="p" {...{
        "href": "https://github.com/tompng/selftrick2024"
      }}>{`ソースコード`}</a>{`が公開されています。`}</p>
    <iframe frameBorder="0" src="https://drive.google.com/file/d/1Dkx15u_5UAGoFqJHCeAuj2FXS-z_U7EE/preview" title="Writing Weird Code" allowFullScreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "100%",
      "height": "auto",
      "aspectRatio": "560 / 420"
    }} data-ratio="1.3333333333333333"></iframe>
    <p>{`引用元:`}<a parentName="p" {...{
        "href": "https://drive.google.com/file/d/1Dkx15u_5UAGoFqJHCeAuj2FXS-z_U7EE/preview"
      }}>{`drive.google.com`}</a></p>
    <h2>{`DAY1 rubykaigiB 「Long journey of Ruby standard library」`}</h2>
    <p>{`Hiroshi SHIBATA さんによる、Ruby の標準ライブラリとは何なのかと、その歴史についての発表でした。`}</p>
    <iframe className="speakerdeck-iframe" frameBorder="0" src="https://speakerdeck.com/andpad/long-journey-of-ruby-standard-library-rubykaigi-2024" title="Long journey of Ruby standard library" allowFullScreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "100%",
      "height": "auto",
      "aspectRatio": "560 / 420"
    }} data-ratio="1.3333333333333333"></iframe>
    <p>{`引用元:`}<a parentName="p" {...{
        "href": "https://speakerdeck.com/andpad/long-journey-of-ruby-standard-library-rubykaigi-2024"
      }}>{`speakerdeck.com`}</a></p>
    <h3>{`組み込みクラスと標準ライブラリの違い`}</h3>
    <p>{`組み込みクラスとは `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`require`}</code>{` しなくても使えるもので、標準ライブラリとは `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`require`}</code>{` しないと使えないものです。標準ライブラリの中には Ruby だけで書かれたものと C 拡張で書かれたものがあります。`}</p>
    <h3>{`歴史と傾向`}</h3>
    <p>{`Ruby の標準ライブラリの数は Ruby1.8 から減少傾向にあります。1.6 から 1.8 では数が増えたようですが、これはインターネットが常時接続ではなかったため、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`gem install`}</code>{` でインストールするより、Ruby をインストールしただけでたくさんの機能が使えたほうが良いのではという背景のもとに増えたとのことです。`}</p>
    <h3>{`default gems と bundled gems という概念の導入`}</h3>
    <p>{`default gems にコミットされたものは自動で Ruby 本体にもコミットされ、bundled gems にコミットされたものは Ruby 本体にコミットされないようになったそうです。
default gems や bundled gemsに関する活動をする理由は、セキュリティ対応と Ruby の持続可能性を高めるためとのことでした。
セキュリティ対応については、サプライチェーンアタックを防ぐために gem 同士の依存関係を少なくするようにしているそうです。これにより脆弱性対応時に単体のライブラリをアップデートするだけで済むようになり、結果として工数を削減することができるようになったのは開発者として大変ありがたいことだと思いました。
Ruby 自体の持続可能性については、Ruby にコミットしたい人に適切に権限を付与できるようにし、ライブラリ単体でも開発できる状態を目指すということだそうで、Ruby のこれからを支える人々の動きを促進する素晴らしいものだと感じました。`}</p>
    <h3>{`影響`}</h3>
    <p>{`default gems が bundled gems になる影響として、bundler の下で使う際に Gemfile に書かないと使えなくなってしまうことが紹介されました。default gems でもバージョン指定する際は Gemfile に書き込む必要があるようです。
これを解決するために、Ruby3.3 では警告機能が実装されました。`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`require "csv"`}</code>{`などで実行すると、Ruby3.4 では bundled gems になる旨を伝える警告が出力されるというものです。これを元に Gemfile に情報を追加すれば良いので、対応がわかりやすくなったというメリットがあります。
また、自分が使っていないライブラリの警告だとしても、他のどのライブラリから呼び出されているのかも確認できるそうです。これを確認する際に、使っていないものであれば削除して依存関係を少なくする整理にも役立つそうです。`}</p>
    <h3>{`感想`}</h3>
    <p>{`引き続き bundled gems の数を増やして default gems の数を減らすことでメンテナンス性を高める方針のようなので、適宜分類をチェックして Gemfile への追加や Gem を使っているかの整理を進めて行きたいです。`}</p>
    <h2>{`DAY2 rubykaigiB 「Let's use LLMs from Ruby 〜 Refine RBS types using LLM 〜」`}</h2>
    <p>{`Shunsuke Mori(kokuyou)さんによる、Ruby で大型言語モデル（LLM）を使用して RBS 型シグネチャを改良するツールである RBS Goose を作成されたことに関する発表でした。`}</p>
    <iframe frameBorder="0" src="https://slides.com/kokuyouwind/lets-use-llms-from-ruby/embed" title="Let's use LLMs from Ruby ~ Refine RBS types using LLM ~" allowFullScreen="true" style={{
      "border": "0px",
      "background": "padding-box padding-box rgba(0, 0, 0, 0.1)",
      "margin": "0px",
      "padding": "0px",
      "borderRadius": "6px",
      "boxShadow": "rgba(0, 0, 0, 0.2) 0px 5px 40px",
      "width": "100%",
      "height": "auto",
      "aspectRatio": "560 / 420"
    }} data-ratio="1.3333333333333333"></iframe>
    <p>{`引用元:`}<a parentName="p" {...{
        "href": "https://slides.com/kokuyouwind/lets-use-llms-from-ruby/"
      }}>{`slides.com`}</a></p>
    <h3>{`RBS とは？ & 既存の RBS 作成手法`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/ruby/rbs"
      }}>{`RBS`}</a>{`とは、Ruby の型構造を定義するためのもので、型検査による安全な開発や補完の強化など開発体験を向上させる目的で使用されます。
既存の RBS 作成手法としては以下の 3 つが紹介されていました。`}</p>
    <ul>
      <li parentName="ul">{`静的構文解析: 高速であるが推測できるものが少ない。attr_accessor などはメタプログラミングで生成されるものなので静的構文解析では RBS に出力されない。ruby にそもそも型情報がないので untyped になる。`}</li>
      <li parentName="ul">{`動的ロード: ロード時にクラス全体が読み込まれ、その結果をリフレクションで RBS に書き出すことで、メタプログラミングで生成している attr_accessor のメソッドも定義される。しかし、こちらも型情報がないので untyped になる。`}</li>
      <li parentName="ul">{`型レベル実行: 実行時の値を渡すときに型の情報を受け渡し、これをとっておいて RBS に出力する。コードを実際に動かす必要がある。
これらの手法では untyped を手作業で直したり、足りないものを補うのが大変であり、これを解決したい動機で RBS Goose を作成されたそうです。`}</li>
    </ul>
    <h3>{`RBS Goose の仕組み`}</h3>
    <p>{`RBS Goose は RBS を生成する既存手法でネックになっていた untyped を手作業で直したり、足りないものを補う作業を LLM の力を借りて解決する仕組みのようでした。
RBS 生成の流れは以下のようになっています。`}</p>
    <ol>
      <li parentName="ol"><code parentName="li" {...{
          "className": "language-text"
        }}>{`hoge.rb`}</code>{` に対して静的構文解析などで `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`hoge.rbs`}</code>{` を作成`}</li>
      <li parentName="ol">{`LLM の入力に使う prompt を組み立て`}
        <ol parentName="li">
          <li parentName="ol">{`この際に `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`hoge.rb`}</code>{` とは全く違う `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`fuga.rb`}</code>{` とそれを静的構文解析などで作った `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`fuga.rbs`}</code>{` と LLM に出力してほしいお手本の `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`refined_fuga.rbs`}</code>{` の 3 つを追加で入れる`}</li>
          <li parentName="ol">{`お手本を入れることで期待する答えを出しやすくする Few-shot プロンプティング という手法を採用している`}</li>
        </ol>
      </li>
      <li parentName="ol">{`LLM に prompt を渡す`}</li>
      <li parentName="ol">{`RBS を出力`}</li>
    </ol>
    <p>{`実用レベルにするには複数ファイルを扱えるようにする必要があり、それをどうするかの実現ビジョンについても語られていました。`}</p>
    <h3>{`LLM を使った開発の Tips`}</h3>
    <p>{`開発時の Tips が共有されていたのですが、その中でも特に学びになったのはテスト時の Tips でした。
従量課金のコストがかかる & 応答時間がかかるのでテストに時間がかかってしまうことを解決するために、`}<a parentName="p" {...{
        "href": "https://github.com/vcr/vcr"
      }}>{`VCR`}</a>{`のような Web モックを利用すると良いということ、 LLM の再現性確保のために Temperature（言語モデルの出力のランダム性を制御するパラメータのこと）の設定は 0 にすることがおすすめであるという Tips が共有されました。`}</p>
    <h3>{`感想`}</h3>
    <p>{`性能については割愛しますが、RBS Goose を用いてより良い RBS を得られると、補完の強化などで開発体験が向上することが期待できそうで、実用化が待ち遠しいです。
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=184Sc0hsnek&list=PLbFmgWm555yYvWS7VGTFipF7ckduvSeT-"
      }}>{`去年の Keynote`}</a>{`で ChatGPT に型を推論させてもある程度形になることが語られていましたが、今年の発表で LLM を使用して型情報の定義をサポートするツールが発表されたことで、RBS への興味関心が大きくなりました！`}</p>
    <h2>{`Matz Keynote`}</h2>
    <p>{`今年の RubyKaigi 最後のセッションは Matz が担当しました。どうしたら Ruby をもっとよくできるかについての話では、パフォーマンス改善がキーになるというお話がありました。
パフォーマンス改善のために必要な要素は多岐にわたるため、これをコミュニティ全体で力を合わせて解決していく必要がある というメッセージや、カンファレンスで人々が話をしたことがきっかけでRubyGems が生まれたことから、コミュニティの人々が一緒に取り組めばもっとRuby を良くすることができるはずである というメッセージを受け、Ruby コミュニティの一員として胸の熱くなる想いでした。
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/802537a37424f43f0465c00e30e032cd/6437c/IMG_3809.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "177.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAkABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAECAwQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAHSUB3bNvIjJZ1uUjaQ7YV//8QAHhAAAQMEAwAAAAAAAAAAAAAAAQACEgMREyIEICH/2gAIAQEAAQUCDrPyayCrHxptUksuVB4YByHrUICZi09P/8QAGREAAwADAAAAAAAAAAAAAAAAAAERECFh/9oACAEDAQE/AYiI0Pmf/8QAFxEAAwEAAAAAAAAAAAAAAAAAABARIf/aAAgBAgEBPwGlWP8A/8QAIxAAAgECBAcAAAAAAAAAAAAAAAERAiESIjEyIDNBYXGBkf/aAAgBAQAGPwKpYZOWjYW1m40r0G0aqSXgy6nT4Jqe4k8pdx64P//EABsQAAMBAQEBAQAAAAAAAAAAAAABESFRQTGR/9oACAEBAAE/IcgLspEPXlFFVP0V4WnERO9ktelMb+CT0LbRcv6TaSX1u4ZckKttiVrW5ogkQ//aAAwDAQACAAMAAAAQzDzP+C//xAAbEQACAQUAAAAAAAAAAAAAAAAAAREQQVFhof/aAAgBAwEBPxBRpNGno8CUuyv/xAAaEQACAgMAAAAAAAAAAAAAAAAAEQExEEFh/9oACAECAQE/EHsYi+D2z//EAB4QAQACAwACAwAAAAAAAAAAAAEAESExYUFxUYHx/9oACAEBAAE/EEYJ6CvvUuSFPIK/JyLYyl70+uQ7aUVYg25xdQLCcqIX14lIRVrQwNpGKNW3eeEI6WwpRisxdXYxpEB2tCUek3CALcL6B11BAdj9ETDuBn//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 3809",
            "title": "IMG 3809",
            "src": "/static/802537a37424f43f0465c00e30e032cd/e5166/IMG_3809.jpg",
            "srcSet": ["/static/802537a37424f43f0465c00e30e032cd/f93b5/IMG_3809.jpg 300w", "/static/802537a37424f43f0465c00e30e032cd/b4294/IMG_3809.jpg 600w", "/static/802537a37424f43f0465c00e30e032cd/e5166/IMG_3809.jpg 1200w", "/static/802537a37424f43f0465c00e30e032cd/d9c39/IMG_3809.jpg 1800w", "/static/802537a37424f43f0465c00e30e032cd/6437c/IMG_3809.jpg 2116w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`メドレーブースに遊びに来てくれた Matz と`}</em></p>
    <h1>{`スポンサー LT の様子`}</h1>
    <p>{`メドレーの LT 登壇は Matz の ClosingLT の直前ということもあり、会場が超満員に。登壇した VPoE の山﨑を応援しようと、サプライズで応援うちわを作っていったのですが、残念ながらあの大舞台からは見えなかったようです（笑）
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/dadd6ce7e28b9c8cb6221793a13580e3/f01b8/DSC00342_mosaic.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAGRbhNnSH//xAAaEAACAwEBAAAAAAAAAAAAAAABAgMEEQAS/9oACAEBAAEFAgrKWDL0Z0V09xu5ytjQ/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQMBAT8ByK//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIxQREy/9oACAEBAAY/AuJ2aO0SlJ1hJHlH/8QAHBAAAwACAwEAAAAAAAAAAAAAAAERITFBUWHB/9oACAEBAAE/IXdupdmEflY5OgTkpS2LEgxo6+EyuabWVT//2gAMAwEAAgADAAAAEMPv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAwEBPxBoJw//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QdGC//8QAHBABAAMAAwEBAAAAAAAAAAAAAQARITFBYVGh/9oACAEBAAE/EDKWULhv5BldaFAW88fY4lR0h0HuxXC4YAou/ZS0ACzsVRM3RATvs//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "DSC00342 mosaic",
            "title": "DSC00342 mosaic",
            "src": "/static/dadd6ce7e28b9c8cb6221793a13580e3/e5166/DSC00342_mosaic.jpg",
            "srcSet": ["/static/dadd6ce7e28b9c8cb6221793a13580e3/f93b5/DSC00342_mosaic.jpg 300w", "/static/dadd6ce7e28b9c8cb6221793a13580e3/b4294/DSC00342_mosaic.jpg 600w", "/static/dadd6ce7e28b9c8cb6221793a13580e3/e5166/DSC00342_mosaic.jpg 1200w", "/static/dadd6ce7e28b9c8cb6221793a13580e3/f01b8/DSC00342_mosaic.jpg 1580w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`うちわは社内で活用させていただきます`}</em></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f7be792a7687ad5830ef35c0bf9319a4/e1596/IMG_5413.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAYCAwQF/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAV+XW3QrJYUf/8QAGRAAAwADAAAAAAAAAAAAAAAAAQIDABAR/9oACAEBAAEFAsII1GSMlEFQ44//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAS/9oACAECAQE/AbC//8QAGRABAQEAAwAAAAAAAAAAAAAAAQARECEi/9oACAEBAAY/Ars4FC9m5IX/xAAbEAEAAgIDAAAAAAAAAAAAAAABABEQMSFBUf/aAAgBAQABPyGUthfJeHMl9uFEtJ1C1wZ//9oADAMBAAIAAwAAABAU7//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/ELP/xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/ENIJ1U//xAAcEAEAAgEFAAAAAAAAAAAAAAABABEhEDFRYYH/2gAIAQEAAT8Qm3J4FWaGdjKFm+kjItNN0PGHZTwOCf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "IMG 5413",
            "title": "IMG 5413",
            "src": "/static/f7be792a7687ad5830ef35c0bf9319a4/e5166/IMG_5413.jpg",
            "srcSet": ["/static/f7be792a7687ad5830ef35c0bf9319a4/f93b5/IMG_5413.jpg 300w", "/static/f7be792a7687ad5830ef35c0bf9319a4/b4294/IMG_5413.jpg 600w", "/static/f7be792a7687ad5830ef35c0bf9319a4/e5166/IMG_5413.jpg 1200w", "/static/f7be792a7687ad5830ef35c0bf9319a4/d9c39/IMG_5413.jpg 1800w", "/static/f7be792a7687ad5830ef35c0bf9319a4/e1596/IMG_5413.jpg 2048w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`あそこまで大きな会場だと思ってなかった…！by 山﨑`}</em></p>
    <p>{`LT で発表させていただきましたが、メドレーはコミュニティへの支援を行っています。6 月 13 日に約 1 年半ぶりの開催となる Roppongi.rb をスポンサードしており、メドレーオフィスで開催予定です。ご興味のある方はぜひご参加ください。
`}<a parentName="p" {...{
        "href": "https://roppongirb.connpass.com/event/319768/"
      }}>{`https://roppongirb.connpass.com/event/319768/`}</a></p>
    <p>{`私も地域 Ruby コミュニティに積極的に参加しようと思いますので、Ruby コミュニティを一緒に盛り上げていきましょう！`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`RubyKaigi 2024 に Ruby Sponsor として協賛した様子をお届けしました。
ブースやパーティーを通じて交流させていただいた皆様、Ruby とそのコミュニティの素晴らしさを存分に体感できるイベントを運営してくださった皆様に心より感謝いたします。`}</p>
    <p>{`メドレーでは領域を問わず、Ruby を積極的に活用して医療ヘルスケアの未来をつくるプロダクトを開発しています。
ご興味を持っていただいた方からのご連絡をお待ちしております。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      